import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import MarkdownPageContent from "../components/page-templates/MarkdownPageTemplate"
import SEO from "../components/helper/SEO"

const TermsPage = ({ data }) => {
  const { title, description } = data.markdownRemark.frontmatter
  const { html } = data.markdownRemark

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
      />
      <MarkdownPageContent content={html} />
    </Layout>
  )
}

export default TermsPage

export const pageQuery = graphql`
  query TermsPage {
    markdownRemark(frontmatter: { contentType: { eq: "terms-page" } }) {
      frontmatter {
        title
        description
      }
      html
    }
  }
`
